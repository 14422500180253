import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2023/3B/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2023/3B/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2023/3B/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2023/3B/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly3B2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/3B/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/3B/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/3B/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/3B/4.webp`;
  
  
  const photos = [
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 2,
    },
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 2,
    },
   
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 2,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 2,
  },
      
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      A Cultural Fusion Journey - Where Ladakh Meets Karnataka 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        Class: 3B   Date: 2 & 3 November 2023
        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
         “People without knowledge of their past history, origin and culture are like trees without roots.”
        <br/>
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 3B presented their assembly on the topic, ‘A Cultural Fusion Journey - Where Ladakh
        Meets Karnataka’ on Thursday, 2 November 2023 and Friday, 3 November 2023.
        <br></br>
        The assembly began with a thought for a day followed by a speech on celebrating the rich cultural heritage
        of two distinct regions of India, Ladakh and Karnataka. These two regions hold a world of wonders and our
        skit, &quot;Cultural Fusion: Ladakh Meets Karnataka,&quot; allowed us to explore their diverse tapestries. 
        <br></br>
        Students also presented the topic by sharing the similarities between Ladakh and Karnataka by displaying
        charts of Ladakh and Karnataka flags, monuments, food, rivers and flora side by side, symbolising harmony,
        coexistence and this added an artistic touch to the assembly.
        <br></br>
        The unique heritage of Ladakh and Karnataka reminded that cultural fusion can be a powerful force for
        unity. One needs to carry this message that appreciates the diverse beauty of our incredible nation. 
          <div>
            
          </div>
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        "Diversity is not about how we differ. Diversity is about embracing one another uniqueness."
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly3B2023;